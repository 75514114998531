import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsList, SearchInputWrapper } from './index.styles'
import { getLang, OBLAST_KATO } from '../../utils/helpers.utils';
import { ArrowRight, DeleteX, PlaceMark, SearchIcon } from '../../assets/icons';
import { ISnp } from '../../interfaces/snp.interface';
import { Text } from '../text.component';
import { useNavigate } from 'react-router-dom';

interface IProps {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: { fillingStatus: string, nameKz: string, nameRu: string, kato: number, value: ISnp, children: any[] }[];
  handleSelectOption: (option: any) => void;
  isSearchFocused: boolean;
  setIsSearchFocused: (value: boolean) => void;
}

export const aktobeOblast = { kato: OBLAST_KATO, nameRu: 'Акмолинская область', nameKz: 'Ақмола облысы', children: [], }

const Search: React.FC<IProps> = ({ searchValue, onChange, options, handleSelectOption, isSearchFocused, setIsSearchFocused }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [hoveredRegion, setHoveredRegion] = useState<number | null>(null);
  const [lastHoveredRegion, setLastHoveredRegion] = useState<number | null>(null);

  return (
    <SearchInputWrapper>
      <div className="icon">
        <PlaceMark />
      </div>
      <input
        type="text"
        placeholder={t('chooseSnp')}
        onChange={onChange}
        value={searchValue}
        onFocus={() => setIsSearchFocused(true)}
      // onBlur={() => setTimeout(() => setIsSearchFocused(false), 100)}
      />
      <div className="icon">
        <SearchIcon />
      </div>

      {isSearchFocused && (
        <OptionsList>
          <div className="header">
            <div className="text">
              <Text color='#818689' fontSize='0.875rem' fontWeight={400}>Выберите населенный пункт</Text>
              <Text color='#118F84' fontSize='0.875rem' fontWeight={600} onClick={() => navigate(`/${OBLAST_KATO}`)} style={{ cursor: 'pointer' }}>Акмолинской области</Text>
            </div>
            <DeleteX className='close' onClick={() => setIsSearchFocused(false)} />
          </div>

          {/* <div className="item oblast" onClick={() => handleSelectOption(aktobeOblast)}>
            <PlaceMark />
            <div className="name">
              <Text fontSize="0.75rem" fontWeight={600} color="var(--primary-color)">
                {aktobeOblast[`name${getLang()}`].trim()}
              </Text>
            </div>
          </div> */}

          <div className="list">
            <div className="left">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`item region ${option.kato === hoveredRegion || option.kato === lastHoveredRegion ? 'hovered' : ''}`}
                  onMouseEnter={() => {
                    setHoveredRegion(option.kato)
                    setLastHoveredRegion(option.kato)
                  }}
                  onMouseLeave={() => setHoveredRegion(null)}
                  onClick={() => handleSelectOption(option)}
                >
                  <div className="placemark-icon">
                    <PlaceMark />
                  </div>
                  <div className="name">
                    <Text fontSize="0.875rem" fontWeight={400} color="#202224">
                      {option[`name${getLang()}`].trim()}
                    </Text>
                  </div>
                  <div className="placemark-icon arrow">
                    <ArrowRight height={10} />
                  </div>
                </div>
              ))}
            </div>

            <div className="right">
              {options
                .filter(option => hoveredRegion ? option.kato === hoveredRegion : lastHoveredRegion ? option.kato === lastHoveredRegion : option.kato === options[0].kato)
                .flatMap(option =>
                  option.children.map((child, childIndex) => (
                    <div key={childIndex} className="item district" onClick={() => handleSelectOption(child)}>
                      <div className="placemark-icon">
                        <PlaceMark />
                      </div>
                      <div className="name">
                        <Text fontSize="0.875rem" fontWeight={400} color="var(--text-color)">
                          {child[`name${getLang()}`].trim()}
                        </Text>
                      </div>
                    </div>
                  ))
                )}
            </div>
          </div>
        </OptionsList>
      )}
    </SearchInputWrapper>
  );
};

export default Search