import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SnpInfoWrapper } from './index.styles';
import { PopulationIcon } from '../../assets/icons';
import { Text } from '../text.component';
import { formatDate, getLang } from '../../utils/helpers.utils';

const SnpInfo: React.FC<{ data: any }> = ({ data }) => {
  const { t } = useTranslation();
  const [generalInfo, setGeneralInfo] = useState<any>(null);
  const [childInfoList, setChildInfoList] = useState<any>(null);

  useEffect(() => {
    if (data) {
      const generalInfo = data.generalInfo;
      const childInfoList = data.childInfoList;
      setGeneralInfo(generalInfo);
      setChildInfoList(childInfoList);
    }
  }, [data]);

  return (
    generalInfo && childInfoList && <SnpInfoWrapper>
      <div className="container">
        <div className="header">
          <div className='left'>
            <div className="icon"><PopulationIcon /></div>
            <Text fontWeight={500} fontSize='0.875rem' color='var(--primary-color)'>{t('info.population').toUpperCase()}</Text>
          </div>

          <div className="left" style={{ gap: '10px' }}>
            <Text fontWeight={400} fontSize='0.875rem' color='rgba(0, 1, 5, 0.5)'>на</Text>
            <Text fontWeight={500} fontSize='0.875rem' color='rgba(0, 1, 5, 1)'>{formatDate(generalInfo.date).replaceAll('-', '.')}</Text>
          </div>
        </div>

        <div className="snp-info__grid">
          <div className="snp-info__item total">
            <Text fontWeight={500} fontSize='0.875rem' color='#818689'>Всего</Text>
            <Text fontWeight={500} fontSize='2.1875rem' color='var(--primary-color)'>{generalInfo?.population?.toLocaleString()}</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{t('form.people')}</Text>
          </div>

          {childInfoList && childInfoList.map((item: any) => (
            <div className="snp-info__item">
              <Text fontWeight={500} fontSize='0.875rem' color='var(--primary-color)'>{item[`name${getLang()}`]}</Text>
              <Text fontWeight={500} fontSize='2.1875rem' color='var(--text-color)' >{item?.population?.toLocaleString()}</Text>
              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{t('form.people')}</Text>
            </div>
          ))}
        </div>
      </div>

      <div className="container" style={{paddingTop: '1.25rem'}}>
        <div className="snp-info__grid">
          <div className="snp-info__item long">
            <Text fontWeight={500} fontSize='0.875rem' color='var(--primary-color)'>{t('aktobe.area')}</Text>
            <Text fontWeight={500} fontSize='2.1875rem' color='var(--text-color)' >{generalInfo?.area?.toLocaleString()}</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>га</Text>
          </div>
          <div className="snp-info__item long">
            <Text fontWeight={500} fontSize='0.875rem' color='var(--primary-color)'>{t('aktobe.avgSalary')}</Text>
            <Text fontWeight={500} fontSize='2.1875rem' color='var(--text-color)' >{generalInfo?.avgSalary?.toLocaleString()}</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{t('form.tenge')}</Text>
          </div>
        </div>
      </div>
    </SnpInfoWrapper>
  )
}

export default SnpInfo