import React, { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next'
import { isRegion, OBLAST_KATO } from '../../utils/helpers.utils';
import { Theme } from '../../contexts/theme.context';
import { useNavigate, useParams } from 'react-router-dom';
import PopulationTab from './info-tabs/population-tab.component';
import ControlTab from './info-tabs/control-tab.component';
import AreaTab from './info-tabs/area-tab.component';

const InfoContainer = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;

  .header { 
    display: flex;
    gap: 0.9375rem;
    padding: 0.625rem 1.25rem;
  }

  .tab { 
    cursor: pointer;
    height: 1.8125rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;

    &.selected {
      border-bottom: 2px solid var(--primary-color);
    }
  }

  .arrow {
    cursor: pointer;
  }

  .ttl {
    text-align: right;
  }

  ${props => props.theme === Theme.DARK && css`
      #area-icon path {
        fill: white;
      }

      #salary-icon path {
        fill: white;
      }
    `}

  .icon {
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--whiteSmoke);
  }

  .label {
    display: flex;
    flex-direction: column;
  }

  .other {
    display: flex;
    gap: 1.25rem;
    width: 100%;
    flex-direction: column;
  }

  .box {
    width: 100%;
    display: flex;
    gap: 0.625rem;
    padding: 0 0.625rem;
    .top {
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }

    .label {
      gap: 0.5rem;
    }
  }
`

export const PopulationInfoContainer = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: column;

  .population-item {
    display: flex;
    border-bottom: 1px solid var(--white);
    height: 2.5rem;
    background: #F6F6F6;
    width: 100%;
    align-items: center;
    padding: 0 0.625rem;

    &__icon {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg path {
      stroke: #CCCCCC;
    }

    .flex-1 {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &.total {
      padding: 0.625rem;
      height: 3.75rem;

      .flex-1 {
        display: block;
      }

      svg path {
        stroke: var(--primary-color);
      }
    }

    &.clickable {
      cursor: pointer;
    }
  }

`;

const tabs: string[] = ['population', 'control', 'area']

const Info: FC<{ info: any, onTabSelect: (tab: string) => void, isAuthenticated: boolean | undefined }> = ({ info, onTabSelect, isAuthenticated }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredTabs, setFilteredTabs] = useState(tabs);

  const handleRegionClick = (kato: string) => {
    isRegion(+info.kato) && navigate(`/${kato}/snp`)
  }

  useEffect(() => {
    if (!isAuthenticated || (kato && +kato !== OBLAST_KATO)) {
      setFilteredTabs(tabs.filter(tab => tab !== 'control'))
      setSelectedTab(tabs.filter(tab => tab !== 'control')[0])
    } else {
      setFilteredTabs(tabs)
    }
  }, [isAuthenticated, kato])

  return (
    info && <InfoContainer>
      <div className="header">
        {filteredTabs.map(tab => (
          <Text
            className={`tab ${selectedTab === tab ? 'selected' : ''}`}
            fontWeight={selectedTab === tab ? 600 : 400}
            color={selectedTab === tab ? 'var(--primary-color)' : '#818689'}
            onClick={() => setSelectedTab(tab)}
            fontSize='0.75rem'
          >
            {t(`info.${tab}`)}
          </Text>
        ))}
      </div>

      {
        selectedTab === tabs[0] && <PopulationTab info={info} handleRegionClick={handleRegionClick} />
      }

      {
        selectedTab === tabs[1] && <ControlTab info={info} onTabSelect={onTabSelect} />
      }

      {
        selectedTab === tabs[2] && <AreaTab info={info} />
      }

    </InfoContainer>
  )
}

export default Info