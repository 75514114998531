import React, { useEffect, useState } from 'react'
import { IMarker, IRegion } from '../../interfaces/snp.interface'

import { CurrentSnpWrapper, Image, SnpInfo } from './index.styles'
import { DefaultGerb, ProdBackground } from '../../assets/images'
import { getLang, isRegion, OBLAST_KATO } from '../../utils/helpers.utils';
import { aktobeOblast } from './search.component';
import { Text } from '../text.component';
import i18n from '../../i18n';

interface IProps {
  selectedRegion: IRegion;
  selectedSnp: IMarker;
  handleSelectOption: (option: any) => void;
}

const CurrentSnp: React.FC<IProps> = ({ selectedRegion, selectedSnp, handleSelectOption }) => {
  const { language } = i18n;

  const [gerb, setGerb] = React.useState<string>(DefaultGerb);

  const [text, setText] = useState<{
    top: string,
    bottom: string
  }>({
    top: '',
    bottom: ''
  })

  const handleRegionClick = () => {
    handleSelectOption(aktobeOblast);
  }

  useEffect(() => {
    if (selectedRegion && selectedSnp) {
      if (selectedRegion.kato === OBLAST_KATO) {
        setText({
          top: aktobeOblast[`name${getLang()}`],
          bottom: language === 'ru' ? 'Республика Казахстан' : 'Қазақстан Республикасы'
        })
      } else if (selectedSnp?.kato && isRegion(+selectedSnp?.kato)) {
        setText({
          top: selectedSnp[`name${getLang()}`],
          bottom: aktobeOblast[`name${getLang()}`]
        })
      } else {
        setText({
          top: selectedSnp?.[`name${getLang()}`],
          bottom: selectedRegion?.[`name${getLang()}`]
        })
      }
    } else if (selectedRegion) {
      if (selectedRegion.kato === OBLAST_KATO) {
        setText({
          top: aktobeOblast[`name${getLang()}`],
          bottom: language === 'ru' ? 'Республика Казахстан' : 'Қазақстан Республикасы'
        })
      } else if (selectedRegion.kato && isRegion(+selectedRegion.kato)) {
        setText({
          top: selectedRegion[`name${getLang()}`],
          bottom: aktobeOblast[`name${getLang()}`]
        })
      }
    }
  }, [selectedRegion, selectedSnp, language])

  return (
    <CurrentSnpWrapper bgImage={ProdBackground}>
      <Image src={gerb} noShadow onClick={handleRegionClick} style={{ cursor: 'pointer' }} />
      <SnpInfo>
        <Text fontSize="1.125rem" color="var(--primary-color)" fontWeight={500} >{text.top}</Text>

        <Text fontSize="0.75rem" color="#818689" fontWeight={400} > {text.bottom}</Text>
      </SnpInfo>

    </CurrentSnpWrapper>
  )
}

export default CurrentSnp