import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Header from './header.component';
import Search from './search.component';
import CurrentSnp from './current-snp.component';
import Passport from './passport.component';

import { Enter, Settings, LoginIcon, ArrowRight } from '../../assets/icons';

import { LeftMenuContainer, LoginContainer } from './index.styles';
import { IMarker, IRegion, ISnp } from '../../interfaces/snp.interface';
import { getUserInfo } from '../../requests/auth.request';
import { getGeneralInfo } from '../../requests/snp.request';
import { getRegionList, getSettlementTree } from '../../requests/kato.request';
import i18n from 'i18next';
import { getLang, OBLAST_KATO } from '../../utils/helpers.utils';
import { useParams } from 'react-router-dom';
import PhotoSwiper from './photo-swiper.components';
import Info from './info.component';
import { Text } from '../text.component';
import ThemeSwitch from './theme-switch.component';
import NavBar from './navbar.component';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (link: string) => void;
  selectedRegion: IRegion;
  setSelectedRegion: (region: IRegion) => void;
  selectedMarker: IMarker;
  setSelectedMarker: (marker: IMarker) => void;
  markers: IMarker[];
  keycloak: import("keycloak-js").KeycloakInstance;
  showLeftMenu: boolean;
  toggleShowLeftMenu: () => void;
}

const LeftMenu: React.FC<IProps> = ({ selectedTab, setSelectedTab, selectedRegion, setSelectedRegion, selectedMarker, setSelectedMarker, keycloak, showLeftMenu, toggleShowLeftMenu }) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();
  const { link, kato } = useParams()

  const [chosenLanguage, setChosenLanguage] = useState<'ru' | 'kz'>('kz');
  const [searchValue, setSearchValue] = useState('');
  const [showLogin, setShowLogin] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [passportInfo, setPassportInfo] = useState<any>(null);
  const [listOptions, setListOptions] = useState<{ fillingStatus: string, nameKz: string, nameRu: string, kato: number, value: ISnp, children: any[] }[]>([]);
  const [info, setInfo] = useState<any>(null)
  const [photos, setPhotos] = useState<any[]>([])
  const [filteredOptions, setFilteredOptions] = useState<{ fillingStatus: string, nameKz: string, nameRu: string, kato: number, value: ISnp, children: any[] }[]>([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleLanguageChange = (val: 'ru' | 'kz') => {
    setChosenLanguage(val);
    localStorage.setItem('lng', val);
    i18n.changeLanguage(val);
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const latinicPattern = /[A-Za-z]/;
    if (latinicPattern.test(inputValue)) {
      return;
    }
    setSearchValue(event.target.value);
  };

  const handleGoToAdminPanel = () => {
    navigate('/admin/snp');
  }

  const handleSelectOption = (option: any) => {
    link ? navigate(`/${option.kato}/${link}`) : navigate(`/${option.kato}`);
    setSelectedMarker(option);
    +option.kato === OBLAST_KATO && setSelectedRegion(option);
    setIsSearchFocused(false);
  }

  const getOptions = () => {
    getSettlementTree(language.toUpperCase() as 'RU' | 'KZ').then(res => {
      getRegionList().then((regionList) => {
        const options = regionList
          .map((item: any) => {
            const found: any = res.data[item[`name${getLang()}`]] || {};
            const children: any = Object
              .keys(found)
              .map((item: string) => ({ kato: found[item][0].pkato, nameKz: found[item][0].pnameKz, nameRu: found[item][0].pnameRu }));
            return ({ ...item, children })
          })

        setListOptions(options)
        setFilteredOptions(options)
      })
    })
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      getUserInfo().then((info: any) => {
        localStorage.setItem('user', JSON.stringify(info));
        setUserInfo(info);
      });
    }

    const lang = localStorage.getItem('lng');
    if (lang) {
      i18n.changeLanguage(lang);
      setChosenLanguage(lang as 'ru' | 'kz');
      getOptions()
    } else {
      i18n.changeLanguage('ru');
      setChosenLanguage('ru');
      getOptions()
    }
  }, []);

  useEffect(() => {
    if (kato) {
      const snpData = JSON.parse(localStorage.getItem('snp') as string);
      const val = kato || snpData.kato;
      if (val) {
        getGeneralInfo(val).then((res: any) => {
          if (res) {
            setInfo({
              kato: res.kato,
              nameKz: res.generalInfo.nameKz,
              nameRu: res.generalInfo.nameRu,
              childInfoList: res.childInfoList,
              ...res.generalInfo
            })
            const akim = {
              nameRu: res.generalInfo.akimNameRu,
              nameKz: res.generalInfo.akimNameKz,
              phone: res.generalInfo.akimPhone,
              inPositionFrom: res.generalInfo.akimInPositionFrom,
              positionKz: res.generalInfo.akimPositionKz,
              positionRu: res.generalInfo.akimPositionRu,
              imageId: res.generalInfo.akimImageId,
            }
            setPassportInfo(akim);
            setPhotos(res.imageIds)
          }
        })
      }
    }
  }, [kato]);

  useEffect(() => {
    if (selectedRegion && selectedMarker && selectedMarker.code) {
      getGeneralInfo(selectedMarker.code).then((res: any) => {
        if (res) {
          setInfo({
            kato: res.kato,
            childInfoList: res.childInfoList,
            ...res.generalInfo
          })
          const akim = {
            nameRu: res.generalInfo.akimNameRu,
            nameKz: res.generalInfo.akimNameKz,
            phone: res.generalInfo.akimPhone,
            inPositionFrom: res.generalInfo.akimInPositionFrom,
            akimPositionKz: res.generalInfo.akimPositionKz,
            akimPositionRu: res.generalInfo.akimPositionRu,
            imageId: res.generalInfo.akimImageId,
          }
          setPassportInfo([{ ...akim, type: 's' }]);
          setPhotos(res.imageIds)
        }
      })
    }
  }, [selectedRegion, selectedMarker])

  useEffect(() => {
    const filteredOptions = listOptions.filter(option => {
      if (
        Object.values(option).some(
          value =>
            value &&
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        return true;
      }

      if (
        option.children &&
        option.children.some((child: any) =>
          Object.values(child).some(
            value =>
              value &&
              typeof value === "string" &&
              value.toLowerCase().includes(searchValue.toLowerCase())
          )
        )
      ) {
        return true;
      }

      return false;
    });

    const filtered = filteredOptions.map(item => ({
      ...item,
      children: item.children.filter((child: any) => child[`name${getLang()}`].toLowerCase().includes(searchValue.toLowerCase()))
    }))
    setFilteredOptions(filtered);

  }, [listOptions, searchValue]);

  useEffect(() => {
    getOptions();
  }, [language])

  return (
    <LeftMenuContainer isShown={showLeftMenu} active={showLogin} isFocused={isSearchFocused}>
      <Header
        chosenLanguage={chosenLanguage}
        onChange={handleLanguageChange}
        showLogin={showLogin}
        setShowLogin={setShowLogin}
      />

      {
        showLogin
          ? <LoginContainer>
            <div className="block">
              <div className="heading">
                <Settings />
                {t('settings')}
                <ThemeSwitch />
              </div>
            </div>

            {
              keycloak.authenticated ? <div className="block">
                <div className="heading">
                  <LoginIcon />
                  <Text fontSize='1rem'>
                    {userInfo?.preferred_username || 'Username'}
                  </Text>
                </div>

                <button onClick={handleGoToAdminPanel}>
                  <Text fontSize='0.75rem'>{t('loginToAdminPanel')}</Text>
                  <Enter />
                </button>
                <button onClick={() => keycloak.logout()}>
                  <Text fontSize='0.75rem'>{t('logout')}</Text>
                  <Enter />
                </button>
              </div>
                : <div className="block">
                  <div className="heading">
                    <LoginIcon />
                    <Text fontSize='0.75rem'>{t('loginToAdminPanel')}</Text>
                  </div>

                  <button onClick={() => keycloak.login()}>
                    <Text fontSize='0.75rem'>{t('login')}</Text>

                    <Enter />
                  </button>
                </div>
            }

            {
              <div className="add-info">
                <a href="https://adilet.zan.kz/rus/docs/V15C0004686" target='_blank' rel="noreferrer">{t('RegionImprovementRules')}</a>
              </div>
            }
          </LoginContainer>
          : <>
            {
              listOptions && listOptions.length > 0
              && <Search
                searchValue={searchValue}
                onChange={handleSearchChange}
                options={filteredOptions}
                handleSelectOption={handleSelectOption}
                isSearchFocused={isSearchFocused}
                setIsSearchFocused={setIsSearchFocused}
              />
            }

            {
              <>
                <PhotoSwiper photos={photos} />

                <CurrentSnp selectedSnp={selectedMarker} selectedRegion={selectedRegion} handleSelectOption={handleSelectOption} />
                <NavBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

                {
                  passportInfo && <div className="scrollable">
                    {passportInfo && <Passport person={passportInfo} />}

                    {<Info isAuthenticated={keycloak.authenticated} info={info} onTabSelect={setSelectedTab} />}
                  </div>
                }
              </>
            }
          </>
      }

    </LeftMenuContainer>
  )
}

export default LeftMenu