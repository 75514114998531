import React, { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { IBudgetItem } from '../../interfaces/snp.interface';
import { currentYear, getBudgetTotals, isRegion } from '../../utils/helpers.utils';
import { ExpandCard } from '../../assets/icons';
import { useParams } from 'react-router-dom';


export const months = {
  ru: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
  kz: ['қаңтарға', 'ақпанға', 'наурызға', 'сәуірге', 'мамырға', 'маусымға',
    'шілдеге', 'тамызға', 'қыркүйекке', 'қазанға', 'қарашаға', 'желтоқсанға']

};
interface IProps {
  handleFullScreen: (obj: any) => void;
  budgetData: IBudgetItem;
}

const InvestProjects: FC<IProps> = ({ handleFullScreen, budgetData }) => {
  const { t, i18n: { language } } = useTranslation();
  const { kato } = useParams();
  const [budgetTotals, setBudgetTotals] = useState<IBudgetItem | null>(null);

  useEffect(() => {
    if (budgetData) {
      setBudgetTotals({
        ...budgetData,
        totalBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'totalBudgetMonthlyProcessing') || 0,
        curBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'curBudgetMonthlyProcessing') || 0,
        devBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'devBudgetMonthlyProcessing') || 0,
        curBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'curBudgetAnnualProcessing') || 0,
        totalBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'totalBudgetAnnualProcessing') || 0,
        devBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'devBudgetAnnualProcessing') || 0,
        totalBudgetMonthlyPlan: +getBudgetTotals(budgetData, 'totalBudgetMonthlyPlan') || 0,
        totalBudgetMonthlyFact: +getBudgetTotals(budgetData, 'totalBudgetMonthlyFact') || 0,
        totalBudgetAnnualPlan: +getBudgetTotals(budgetData, 'totalBudgetAnnualPlan') || 0
      })
    }
  }, [budgetData])

  return (
    budgetTotals && <BasicStatsItem id="invest-projects">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`budgetInvestmentProjectStats.osvoenie`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{kato && isRegion(+kato) ? 'млн. ' : 'тыс. '}{t('form.tenge')}</Text>
        </div>

        <div className="right" style={{ display: 'flex', alignItems: 'center' }}>
          <span className='unit-container'>
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'InvestProjects' })} />
          </span>
        </div>

      </div>
      <div className="body">
        <div className="budget-grid">
          <div className='budget-grid__header'></div>
          {['plan', 'fact', 'processing', 'plan', 'processing'].map((item, index) => (
            <Text key={index} className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>{t('budget.table-' + item)} </Text>
          ))}
          <div className='budget-grid__header bordered'></div>
          <Text className='budget-grid__header span-3 bordered' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>{t('budget.table-date', { date: `1 ${months[language as 'ru' | 'kz'][new Date().getMonth()]}` })} </Text>
          <Text className='budget-grid__header span-2 bordered' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>{t('budget.table-year', { year: currentYear })} </Text>
          <div className='bordered'></div>

          {['total', 'cur', 'dev'].map((item: string, index) => (
            <Fragment key={item}>
              <Text className='budget-grid__item' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >{t(`budget.${item}`)}</Text>
              <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals[`${item}BudgetMonthlyPlan` as keyof IBudgetItem]}</Text>
              <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals[`${item}BudgetMonthlyFact` as keyof IBudgetItem]}</Text>
              <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals[`${item}BudgetMonthlyProcessing` as keyof IBudgetItem]}%</Text>
              <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals[`${item}BudgetAnnualPlan` as keyof IBudgetItem]}</Text>
              <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals[`${item}BudgetAnnualProcessing` as keyof IBudgetItem]}%</Text>
            </Fragment>
          ))}

        </div>
      </div>
    </BasicStatsItem>
  )
}

export default InvestProjects