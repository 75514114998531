import React, { FC } from 'react'
import { BasicStatsItem } from './index.styles'
import { Text } from '../text.component';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getLang, getPopulationDemandTotal } from '../../utils/helpers.utils';
import { IPopulationDemand } from '../../interfaces/snp.interface';
import { ExpandCard } from '../../assets/icons';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels, ...registerables);

interface IProps {
  data: any;
  kato: string;
}

const PopulationDemand: FC<IProps> = ({ data, kato }) => {
  const { t } = useTranslation();

  const handleLinkClick = () => {
    window.open(`https://aktobe.csi.kz/#/snp?region=${kato}&year=2023`, '_blank');
  }

  return (
    data && <BasicStatsItem id="demand">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('populationDemandStats.title-short')}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{t('form.quantity-short')}</Text>
        </div>
        <div className="right">
          <span className='unit-container' >
            <ExpandCard onClick={() => handleLinkClick()} />
          </span>
        </div>
      </div>

      <div className="body with-table">
        <table>
          <thead>
            <tr>
              <td></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='#9ED564' >{t('populationDemandStats.table-normalConstruction')}</Text></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='#DE9F55' >{t('populationDemandStats.table-constructionRepairRequirement')} </Text></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='#D76C6C' >{t('populationDemandStats.table-newConstructionRequirement')} </Text></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='var(--total)' >{t('populationDemandStats.table-total')} </Text></td>
            </tr>
          </thead>
          <tbody>
            {
              data.slice(0, 6).map((item: IPopulationDemand) => (
                <tr className="table-row" key={item.nameRu}>
                  <td>
                    <Text className='subType' fontSize='0.75rem' lineHeight="0.625rem" color='var(--text-color)' fontWeight={400}>
                      {item[`name${getLang()}`]}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='#9ED564' fontWeight={700}>
                      {item.normalConstruction}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='#DE9F55' fontWeight={700}>
                      {item.constructionRepairRequirement}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='#D76C6C' fontWeight={700}>
                      {item.newConstructionRequirement}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='var(--total)' fontWeight={700}>
                      {getPopulationDemandTotal(item)}
                    </Text>
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>
    </BasicStatsItem >
  )
}

export default PopulationDemand