import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { ArrowDown, GoUp, Save } from '../../assets/icons';
import { checkVal, defaultPopulationDemandStats, getLang, getPopulationDemandTotal, handleGoUp, isRegion, OBLAST_KATO, ruToEnDemands, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { updateFormPartial } from '../../requests/supervisor.request';
import { IPopulationDemand } from '../../interfaces/snp.interface';


const PopulationDemandPage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<any[]>([]);
  const [snps, setSnps] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [populationDemandStats, setPopulationDemandStats] = useState<IPopulationDemand[]>([]);
  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});

  const validateForm = (data: any) => {
    setErrors({});
    let res = true;
    const populationDemandData: any[] = populationDemandStats;
    let index = 0;
    if (populationDemandData.some((item: any) => checkVal(item.constructionRepairRequirement) || checkVal(item.newConstructionRequirement) || checkVal(item.normalConstruction))) {
      for (const item of populationDemandData) {
        const keys = Object.keys(item);
        for (const key of keys) {
          if (!checkVal(item[key])) {
            const errNotification = ruToEnDemands[item.nameRu] ? `populationDemandStats.errors.${ruToEnDemands[item.nameRu]}-${key}` : 'errors.streetName';
            const errKey = ['nameKz', 'nameRu'].includes(key) ? 'name' : key;
            setErrors((prev: any) => ({ ...prev, [`populationDemandStats[${index}].${errKey}`]: true }))
            toast.error(t(errNotification, language));
            return;
          }
        }
        index++;
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      populationDemandStats,
    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    setSnps(regions.find((item) => +item.value === +e.target.value)?.children || []);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/population-demand`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      const populationDemandList = res.populationDemandStats?.length === 0
        ? defaultPopulationDemandStats.map((item) =>
        ({
          nameRu: t(`populationDemandStats.${item}`, 'ru'),
          nameKz: t(`populationDemandStats.${item}`, 'kz'),
          constructionRepairRequirement: '',
          newConstructionRequirement: '',
          normalConstruction: ''
        }))
        : res.populationDemandStats;
      setPopulationDemandStats(populationDemandList);

      const types = populationDemandList.reduce((acc: any, item: any) => {
        acc[item.id] = false;
        return acc;
      }, {});

      setIsOpen(types);

      setIsLoading(false);
    });

  }, [region, snp, kato])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        populationDemandStats && <FormBlock type='white'>
          <div className="title bold">{t('populationDemandStats.title')}</div>

          {
            populationDemandStats?.map((item: any, index: number) => (
              <FormBlock type='white' key={index}>
                <FormGroup>
                  <div className='c-pointer' onClick={() => {
                    setIsOpen({ ...isOpen, [item.id]: !isOpen[item.id] });
                  }}>
                    <label
                      htmlFor={`populationDemandStats[${index}].name${lang}`}
                      className="required"

                    >
                      {t(`populationDemandStats.name`, lang)}
                    </label>
                    {
                      isOpen[item.id]
                        ? <ArrowDown width={10} style={{ transform: 'rotate(180deg)' }} />
                        : <ArrowDown width={10} />
                    }
                  </div>

                  <Field
                    name={`populationDemandStats[${index}].name${lang}`}
                    className={`${errors[`populationDemandStats[${index}].name${lang}`] ? 'error' : ''}`}
                    onChange={(e: any) => {
                      setPopulationDemandStats(populationDemandStats.map((item: any, i: number) => i === index ? {
                        ...item,
                        [`name${lang}`]: e.target.value
                      } : item));
                      setFieldValue(`populationDemandStats[${index}].name${lang}`, e.target.value);
                    }}
                    value={item[`name${lang}`] || ''}
                    disabled={defaultPopulationDemandStats.find(key => item.nameRu === t(`populationDemandStats.${key}`, 'ru'))}
                  />
                </FormGroup>

                {isOpen[item.id] && <>
                  {
                    ['normalConstruction', 'newConstructionRequirement', 'constructionRepairRequirement'].map((key: string) => (
                      <Fragment key={key}>
                        <FormGroup>
                          <label
                            htmlFor={`populationDemandStats[${index}].${key}`}
                            className="required"
                          >{t(`populationDemandStats.${key}`, lang)}
                          </label>
                          <Field
                            name={`populationDemandStats[${index}].${key}`}
                            className={`${errors[`populationDemandStats[${index}].${key}`] ? 'error' : ''}`}
                            type='number'
                            as="input"
                            min={0}
                            onChange={(e: any) => {
                              setPopulationDemandStats(populationDemandStats.map((item: any, i: number) => i === index ? {
                                ...item,
                                [key]: e.target.value
                              } : item));
                              setFieldValue(`populationDemandStats[${index}].${key}`, e.target.value);
                            }}
                            value={item[key] || ''}
                          />
                        </FormGroup>
                      </Fragment>
                    ))
                  }
                  <FormGroup>
                    <label
                      htmlFor={`populationDemandStats[${index}].total`}
                      className="required"
                    >{t(`populationDemandStats.total`, lang)}
                    </label>
                    <Field
                      name={`populationDemandStats[${index}].total`}
                      className={`${errors[`populationDemandStats[${index}].total`] ? 'error' : ''}`}
                      type='number'
                      as="input"
                      min={0}
                      disabled
                      value={getPopulationDemandTotal(item) || ''}
                    />
                  </FormGroup>
                </>
                }
              </FormBlock>
            ))
          }
        </FormBlock>
      }
    </div>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setRegions, setRegion, setSnps, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default PopulationDemandPage