import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { ArrowDown, GoUp, Save } from '../../assets/icons';
import { checkVal, findIndexByKey, getIncreaseVal, getLang, getYearVal, handleGoUp, isRegion, OBLAST_KATO, t, updateKatoAccessList, years } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { updateFormPartial } from '../../requests/supervisor.request';


const InfrastructurePage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<any[]>([]);
  const [snps, setSnps] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);

  const [basicStats, setBasicStats] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [isSubOpen, setIsSubOpen] = useState<Record<string, boolean>>({});

  const validateForm = (data: any) => {
    setErrors({});
    let res = true;
    const foodData = data.basicStats.filter((item: any) => item.type === 'INFRASTRUCTURE')
    if (foodData.some((item: any) => checkVal(item.currentYearVal) || checkVal(item.previousYearVal))) {
      for (const item of foodData) {
        if (!checkVal(item.currentYearVal) || !checkVal(item.previousYearVal)) {
          setErrors((prev: any) => ({ ...prev, [`INFRASTRUCTURE-${item.subType}`]: true }))
          toast.error(t(`errors.INFRASTRUCTURE-${item.subType}`, language));
          return false;
        }
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      basicStats,
    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));

    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    setSnps(regions.find((item) => +item.value === +e.target.value)?.children || []);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/infrastructure`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      setBasicStats(res.basicStats);

      const types = res.basicStats.filter((item: any) => item.type === 'INFRASTRUCTURE').reduce((acc: any, item: any) => {
        acc[item.subType] = false;
        return acc;
      }, {});

      setIsSubOpen(types);

      setIsLoading(false);
    });


  }, [region, snp, kato])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      <FormBlock type='white'>
        <div className="title bold c-pointer" onClick={() => setIsOpen(!isOpen)}>
          {t(`new-fields.INFRASTRUCTURE`, lang)}
          {
            isOpen
              ? <ArrowDown width={10} style={{ transform: 'rotate(180deg)' }} />
              : <ArrowDown width={10} />
          }
        </div>

        {
          isOpen && basicStats.filter((item: any) => item.type === 'INFRASTRUCTURE').map((stat: any) =>
            <FormBlock type='white'>
              <div className="title bold c-pointer" onClick={() => setIsSubOpen((prev: any) => ({ ...prev, [stat.subType]: !prev[stat.subType] }))}>
                {t(`new-fields.${stat.subType}`, lang)}
                {
                  isSubOpen[stat.subType]
                    ? <ArrowDown width={10} style={{ transform: 'rotate(180deg)' }} />
                    : <ArrowDown width={10} />
                }
              </div>

              {
                isSubOpen[stat.subType] && <>
                  <div className="title bold">{t('form.by-snp', lang)}</div>

                  {years.map((year) => (
                    <FormGroup key={year}>
                      <label
                        htmlFor={`basicStats[${findIndexByKey(`INFRASTRUCTURE-${stat.subType}`, basicStats)}].${getYearVal(year)}`}
                        className="required"
                      >
                        {t(`new-fields.${stat.type}-${stat.subType}`, lang).replace('&year', year)}
                      </label>

                      <Field
                        name={`basicStats[${findIndexByKey(`INFRASTRUCTURE-${stat.subType}`, basicStats)}].${getYearVal(year)}`}
                        type="number"
                        as="input"
                        value={basicStats[findIndexByKey(`INFRASTRUCTURE-${stat.subType}`, basicStats)]?.[getYearVal(year)] || ''}
                        onChange={(e: any) => {
                          const val = e.target.value;
                          if (val === '') {
                            setFieldValue(`basicStats[${findIndexByKey(`INFRASTRUCTURE-${stat.subType}`, basicStats)}].${getYearVal(year)}`, '');
                            setBasicStats(
                              basicStats.map((item: any, i: number) => i === findIndexByKey(`INFRASTRUCTURE-${stat.subType}`, basicStats) ? {
                                ...item,
                                [getYearVal(year)]: ''
                              } : item)
                            )
                          } else {
                            setFieldValue(`basicStats[${findIndexByKey(`INFRASTRUCTURE-${stat.subType}`, basicStats)}].${getYearVal(year)}`, Math.max(0, val).toString());
                            setBasicStats(
                              basicStats.map((item: any, i: number) => i === findIndexByKey(`INFRASTRUCTURE-${stat.subType}`, basicStats) ? {
                                ...item,
                                [getYearVal(year)]: Math.max(0, val).toString()
                              } : item)
                            )
                          }
                        }}
                        className={`${errors[`${stat.type}-${stat.subType}`]
                          ? 'error'
                          : ''
                          }`}
                      />
                    </FormGroup>
                  ))}

                  <FormGroup>
                    <label
                      htmlFor={`${stat.type}-${stat.subType}-increaseVal`}
                      className="required"
                    >
                      {t(`new-fields.${stat.type}-${stat.subType}-increaseVal`, lang)}
                    </label>

                    <Field
                      name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}]-increaseVal`}
                      type="number"
                      as="input"
                      value={getIncreaseVal(stat, basicStats) !== null ? getIncreaseVal(stat, basicStats) : basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.increaseVal}
                      disabled
                    />
                  </FormGroup>

                  <div className="title bold">{t('form.by-population', lang)}</div>
                  {
                    years.map((year) => (
                      <FormGroup key={year}>
                        <label
                          htmlFor={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.${getYearVal(year)}`}
                          className="required"
                        >
                          {t(`new-fields.${stat.type}-${stat.subType}`, lang).replace('&year', year)}
                        </label>
                        <Field
                          name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.${getYearVal(year)}`}
                          type="number"
                          as="input"
                          value={basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.additionalInfo?.[getYearVal(year)] || ''}
                          onChange={(e: any) => {
                            const val = e.target.value;
                            if (val === '') {
                              setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.${getYearVal(year)}`, '');
                              setBasicStats(basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                                ...item,
                                additionalInfo: {
                                  ...item.additionalInfo,
                                  [getYearVal(year)]: ''
                                }
                              } : item))
                            } else {
                              setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].${getYearVal(year)}`, Math.max(0, val).toString());
                              setBasicStats(basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                                ...item,
                                additionalInfo: {
                                  ...item.additionalInfo,
                                  [getYearVal(year)]: Math.max(0, val).toString()
                                }
                              } : item))
                            }
                          }}
                          className={`${errors[`${stat.type}-${stat.subType}`]
                            ? 'error'
                            : ''
                            }`}
                        />
                      </FormGroup>
                    ))
                  }
                  <FormGroup>
                    <label
                      htmlFor={`${stat.type}-${stat.subType}-additionalInfo-increaseVal`}
                      className="required"
                    >
                      {t(`new-fields.${stat.type}-${stat.subType}-increaseVal`, lang)}
                    </label>

                    <Field
                      name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo-increaseVal`}
                      type="number"
                      as="input"
                      value={
                        ((+basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.additionalInfo?.currentYearVal || 0)
                          - (+basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.additionalInfo?.previousYearVal || 0)).toFixed(1)}
                      disabled
                    />
                  </FormGroup>
                </>
              }
            </FormBlock>
          )
        }
      </FormBlock>
    </div>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setRegions, setRegion, setSnps, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default InfrastructurePage