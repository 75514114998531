import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { BasicStatsItem } from './index.styles';
import { useTranslation } from 'react-i18next';
import { getLang, OBLAST_KATO } from '../../utils/helpers.utils';
import {
  AgricultureIcon,
  BeefIcon,
  CarrotIcon,
  ChickenIcon,
  ConnectionIcon,
  CraneIcon,
  CucumberIcon,
  DeltaIcon,
  EggIcon,
  ExpandCard,
  GasIcon,
  GoodIcon,
  InternetIcon,
  LambIcon,
  MilkIcon,
  NotSatisfactoryIcon,
  OnionIcon,
  PotatoIcon,
  ProductionIcon,
  RoadIcon,
  SalaryIcon,
  SatisfactoryIcon,
  TomatoIcon,
  TrendDown,
  TrendUp,
  WaterIcon,
} from '../../assets/icons';
import { Text } from '../text.component';
import ThemeContext from '../../contexts/theme.context';
import { IRoadShare } from '../../interfaces/snp.interface';

interface IProps {
  basicStats: any;
  additionalStats: any;
  handleFullScreen?: ({ isOpen, type }: any) => void;
  filtered: string[]
  kato?: number
  totalRoadShareStats?: IRoadShare
}

export const properties: any = {
  ROAD: {
    unit: '%',
    NONE: {
      icon: <RoadIcon id="road-icon" />,
      hasIcon: true
    }
  },
  FOOD: {
    unit: '%',
    LAMB: {
      icon: <LambIcon />,
      hasIcon: true
    },
    EGG: {
      icon: <EggIcon />,
      hasIcon: true
    },
    BEEF: {
      icon: <BeefIcon />,
      hasIcon: true
    },
    POTATO: {
      icon: <PotatoIcon />,
      hasIcon: true
    },
    CUCUMBER: {
      icon: <CucumberIcon />,
      hasIcon: true
    },
    ONION: {
      icon: <OnionIcon />,
      hasIcon: true
    },
    MILK: {
      icon: <MilkIcon />,
      hasIcon: true
    },
    CARROT: {
      icon: <CarrotIcon />,
      hasIcon: true
    },
    TOMATO: {
      icon: <TomatoIcon />,
      hasIcon: true
    },
    CHICKEN: {
      icon: <ChickenIcon />,
      hasIcon: true
    },
  },
  SER: {
    unit: "млрд тг",
    INDUSTRIAL_PRODUCTION: {
      icon: <ProductionIcon />,
      hasIcon: true
    },
    AGRICULTURE: {
      icon: <AgricultureIcon />,
      hasIcon: true
    },
    CONSTRUCTION: {
      icon: <CraneIcon />,
      hasIcon: true
    },
    INVESTMENT: {
      icon: <SalaryIcon />,
      hasIcon: true
    },
    // HOUSING: {
    //   icon: <HouseIcon />,
    //   hasIcon: true
    // },
  },
  INFRASTRUCTURE: {
    unit: '%',
    GAS_SUPPLY: {
      hasIcon: true,
      icon: <GasIcon />,
    },
    WATER_SUPPLY: {
      hasIcon: true,
      icon: <WaterIcon />,
    },
    INTERNET_ACCESS: {
      hasIcon: true,
      icon: <InternetIcon />,
    },
    MOBILE_COMMUNICATION: {
      hasIcon: true,
      icon: <ConnectionIcon />,
    }
  },
  ADDITIONAL_STAT: {
    unit: '%',
    ADDITIONAL_SUB_STAT: {
      hasIcon: false
    }
  }
}

const vals = [
  {
    label: '2023',
    value: 'previousYearVal'
  },
  {
    label: '2024',
    value: 'currentYearVal'
  },
]

const roadIcons: any = {
  good: <GoodIcon />,
  satisfactory: <SatisfactoryIcon className='not-fill' />,
  'not-satisfactory': <NotSatisfactoryIcon />
}

const roadDataMap: any = {
  good: 'totalPrevYearGoodConditionPercentage',
  satisfactory: 'totalPrevYearSatisfactoryConditionPercentage',
  'not-satisfactory': 'totalPrevYearPoorConditionPercentage'
}

const BasicStats: FC<IProps> = ({ basicStats, additionalStats, handleFullScreen, filtered = [], kato, totalRoadShareStats }) => {
  const { t, i18n: { language } } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [sortedKeys, setSortedKeys] = useState(Object.keys(basicStats));
  const [updatedBasicStats, setUpdatedBasicStats] = useState<any>(null);
  const [roadData, setRoadData] = useState<any | null>(null);

  const [selectedVal, setSelectedVal] = useState('currentYearVal');

  const sortOrder = ['SER', 'FOOD', 'ROAD'];

  const sortByKeys = (keys: string[]) => {
    const sorted = keys.sort((a, b) => {
      const indexA = sortOrder.indexOf(a);
      const indexB = sortOrder.indexOf(b);

      if (indexA === -1 && indexB === -1) {
        return a.localeCompare(b); // If both are not in sortOrder, sort alphabetically
      } else if (indexA === -1) {
        return 1; // If a is not in sortOrder, b comes first
      } else if (indexB === -1) {
        return -1; // If b is not in sortOrder, a comes first
      } else {
        return indexA - indexB; // If both are in sortOrder, sort according to their index
      }
    });

    return sorted;
  };

  useEffect(() => {
    const updated = {
      ...basicStats,
      SER: basicStats.SER?.sort((a: any, b: any) => +a.increaseVal - +b.increaseVal).filter((item: any) => item.subType !== 'HOUSING'),
      FOOD: basicStats.FOOD?.sort((a: any, b: any) => +b[selectedVal] - +a[selectedVal]),
    }

    if (filtered && filtered.length > 0) {
      const filteredObj = {
        ...Object.keys(updated).reduce((acc: any, key: string) => {
          if (filtered.includes(key)) {
            acc[key] = updated[key];
          }
          return acc;
        }, {})
      }
      setUpdatedBasicStats(filteredObj);
      basicStats && Object.keys(basicStats).length > 0 && setSortedKeys(sortByKeys(Object.keys(filteredObj)));
    } else {
      setUpdatedBasicStats(updated);
      basicStats && Object.keys(basicStats).length > 0 && setSortedKeys(sortByKeys(Object.keys(updated)));
    }

  }, [basicStats, selectedVal]);

  useEffect(() => {
    if (totalRoadShareStats) {
      setRoadData(totalRoadShareStats)
    }
  }, [totalRoadShareStats]);

  return (
    <>
      {
        updatedBasicStats && sortedKeys.length > 0
          ? <>
            {
              sortedKeys?.map((key) =>
                <>
                  {key === 'FOOD'
                    ? updatedBasicStats[key] && <BasicStatsItem theme={theme} key={key} id={key}>
                      <div className="title">
                        <div className="left">
                          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`new-fields.${key}`)}</Text>
                        </div>
                        <div className="right">
                          <span className='unit-container'>
                            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({
                              type: key,
                              isOpen: true
                            })} />
                          </span>
                        </div>
                      </div>
                      <div className="body padded">
                        <div className='year-select'>

                          {language === 'ru' && <Text fontSize='0.75rem' color='var(--text-color)' fontWeight={400}>
                            посмотреть отчет за:
                          </ Text>}

                          {vals.map((val) => (
                            <Text key={val.value} className='year-value' onClick={() => setSelectedVal(val.value)} fontSize='0.75rem' color={val.value === selectedVal ? 'var(--primary-color)' : 'var(--gray)'} fontWeight={400}>
                              {val.label}
                            </Text>
                          ))}

                          {
                            language === 'kz' && <Text fontSize='0.75rem' color='var(--text-color)' fontWeight={400}>
                              жылғы есепті қарау
                            </ Text>
                          }
                        </div>
                        <div className="food-grid">
                          {
                            updatedBasicStats[key] && updatedBasicStats[key]?.map((stat: any, index: number) => (
                              <div className={`food-grid__item ${stat.type}`} key={properties[key][stat.subType]?.toString() + index}>
                                <div className="icon-container">
                                  <div className="icon">{properties[key][stat.subType]?.icon}</div>
                                  <Text fontSize='0.75rem' color='var(--text-color)' className='SER-value' fontWeight={400}>
                                    {t(`new-fields.${stat.subType}`)}
                                  </ Text>
                                </div>
                                <div className="increase">
                                  <Text className='percent-value' fontSize='1rem' color='var(--primary-color)' fontWeight={400}>
                                    {stat[selectedVal] || 0}%
                                  </Text>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </BasicStatsItem >
                    : key === 'INFRASTRUCTURE'
                      ? <BasicStatsItem theme={theme} key={key} id={key}>
                        <div className="title">
                          <div className="left">
                            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`new-fields.${key}`)}</Text>
                            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{t('new-fields.year-conclusion', { year: 2023 })}</Text>
                          </div>
                          <div className="right">
                            <span className='unit-container'>
                              <ExpandCard onClick={() => handleFullScreen && handleFullScreen({
                                type: key,
                                isOpen: true
                              })} />
                            </span>
                          </div>
                        </div>
                        <div className={`body padded`}>
                          <div className="headers">
                            <Text fontSize='0.5rem' color='var(--text-color)' fontWeight={400} style={{ width: '3.75rem' }}>
                              <DeltaIcon height={'0.5rem'} />  Рост за год
                            </ Text>
                            <Text fontSize='0.5rem' color='var(--text-color)' fontWeight={400} style={{ width: '3rem' }}>
                              {t('form.by-snp')}
                            </ Text>
                            <Text fontSize='0.5rem' color='var(--text-color)' fontWeight={400} style={{ width: '3.75rem' }}>
                              {t('form.by-population')}
                            </ Text>
                          </div>
                          {
                            updatedBasicStats[key] && updatedBasicStats[key]?.map((stat: any, index: number) => (
                              <React.Fragment key={index}>
                                <div className={`body__item with-icon stretched ${key}`} key={properties[key][stat.subType]?.toString() + index}>
                                  <div className="icon-container">
                                    <div className="icon">{properties[key][stat.subType].icon}</div>
                                    <Text fontSize='0.75rem' color='var(--text-color)' className='SER-value' fontWeight={400}>
                                      {t(`new-fields.${stat.subType}`)}
                                    </ Text>
                                  </div>
                                  <div className="increase">
                                    <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? 'var(--success-green)' : 'var(--failure-red)'} fontWeight={700}>
                                      {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                      {stat.increaseVal ? +stat.increaseVal >= 0 ? `+${stat.increaseVal.toFixed(1)}` : ` ${stat.increaseVal.toFixed(1)}` : '+0.0'}%
                                    </Text>

                                    <Text className='percent-value' style={{ minWidth: '3rem' }} fontSize={'1rem'} color='var(--primary-color)' fontWeight={400}>
                                      {stat.currentYearVal?.toLocaleString() || 0}%
                                    </Text>
                                    <Text className='percent-value bordered' fontSize={'1rem'} color='var(--primary-color)' fontWeight={400}>
                                      {stat.additionalInfo?.currentYearVal?.toLocaleString() || 0}%
                                    </Text>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))
                          }
                        </div>
                      </BasicStatsItem>
                      : key === 'ROAD'
                        ? roadData && updatedBasicStats[key] && <BasicStatsItem theme={theme} key={key} id={key}>
                          <div className="title">
                            <div className="left">
                              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`new-fields.${key}`)}</Text>
                              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>по итогам 2023 года</Text>
                            </div>
                            <div className="right">
                              <span className='unit-container'>
                                <ExpandCard onClick={() => handleFullScreen && handleFullScreen({
                                  type: key,
                                  isOpen: true
                                })} />
                              </span>
                            </div>
                          </div>
                          <div className={`body padded with-icon`}>
                            {
                              updatedBasicStats[key] && updatedBasicStats[key]?.map((stat: any, index: number) => (
                                <React.Fragment key={index}>
                                  <div className={`body__item ${stat.type} with-icon stretched`}>
                                    <div className="icon-container">
                                      <div className="icon"><svg><path></path></svg></div>
                                      <Text fontSize='0.75rem' color='var(--text-color)' className='SER-value' fontWeight={700}>
                                        {t(`new-fields.road-share`)}
                                      </ Text>
                                    </div>
                                    <div className="increase">
                                      <Text
                                        className='currentYearVal'
                                        style={{ minWidth: '3.75rem' }}
                                        fontSize='1rem'
                                        color='var(--primary-color)'

                                        fontWeight={700}
                                      >
                                        {roadData.prevYearTotalFineRoadPercentage}%
                                      </Text>
                                    </div>
                                  </div>
                                  {['good', 'satisfactory', 'not-satisfactory'].map((typeKey) => (
                                    <div className={`body__item ${stat.type} with-icon stretched`} key={properties[key][stat.subType]?.toString() + index + typeKey}>
                                      <div className="icon-container">
                                        <div className="icon">{roadIcons[typeKey]}</div>
                                        <Text fontSize='0.75rem' color='var(--text-color)' className='SER-value' fontWeight={400}>
                                          {t(`new-fields.${typeKey}`)}
                                        </ Text>
                                      </div>

                                      <div className="increase">
                                        <Text
                                          className='currentYearVal'
                                          style={{ minWidth: '3.75rem' }}
                                          fontSize='1rem'
                                          color='var(--primary-color)'

                                          fontWeight={400}
                                        >
                                          {roadData[roadDataMap[typeKey]]}%
                                        </Text>
                                      </div>
                                    </div>
                                  ))}
                                </React.Fragment>
                              ))
                            }
                          </div>
                        </BasicStatsItem>
                        : updatedBasicStats[key] && <BasicStatsItem theme={theme} key={key} id={key}>
                          <div className="title">
                            <div className="left">
                              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`new-fields.${key}`)}</Text>
                              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{key === 'SER' ? t(`new-fields.month-conclusion`, { month: new Date().getMonth() }) : 'по итогам 2023'}</Text>
                            </div>
                            <div className="right">
                              <span className='unit-container'>
                                <ExpandCard onClick={() => handleFullScreen && handleFullScreen({
                                  type: key,
                                  isOpen: true
                                })} />
                              </span>
                            </div>
                          </div>
                          <div className={`body padded ${['ROAD', 'SER'].includes(key) && 'with-icon'}`}>
                            <div className="headers" style={{ gap: '0.3125rem', paddingRight: '0.625rem' }}>
                              <Text fontSize='0.625rem' color='var(--gray)' fontWeight={400} style={{ width: '3.25rem' }}>
                                ИФО
                              </ Text>
                              <Text fontSize='0.625rem' color='var(--gray)' fontWeight={400} style={{ width: '3.25rem' }}>
                                {kato && +kato === OBLAST_KATO ? 'млрд тг' : 'млн тг'}
                              </ Text>
                            </div>
                            {
                              updatedBasicStats[key] && updatedBasicStats[key]?.map((stat: any, index: number) => (
                                properties[key][stat.subType] && <React.Fragment key={index}>
                                  {
                                    properties[key][stat.subType]?.hasIcon
                                      ? <div className={`body__item with-icon ${['INFRASTRUCTURE', 'SER'].includes(key) && `stretched`}  ${key}`} key={properties[key][stat.subType]?.toString() + index}>
                                        <div className="icon-container">
                                          <div className="icon">{properties[key][stat.subType].icon}</div>
                                          {['INFRASTRUCTURE', 'SER'].includes(key) && <Text fontSize='0.75rem' color='var(--text-color)' className='SER-value' fontWeight={400}>
                                            {t(`new-fields.${stat.subType}`)}
                                          </ Text>}
                                        </div>
                                        <div className="increase">
                                          {
                                            ['INFRASTRUCTURE'].includes(key) && <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? 'var(--success-green)' : 'var(--failure-red)'} fontWeight={700}>
                                              {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                              {stat.increaseVal ? +stat.increaseVal >= 0 ? `+${stat.increaseVal.toFixed(1)}` : ` ${stat.increaseVal.toFixed(1)}` : '+0.0'}%
                                            </Text>
                                          }

                                          {
                                            ['SER'].includes(key) && <Text className='percent-value' fontSize={['INFRASTRUCTURE', 'SER'].includes(key) ? '1rem' : '1.875rem'} color='var(--primary-color)' fontWeight={400}>
                                              {stat.ifo || '+0.0'}%
                                            </Text>
                                          }

                                          <Text className='percent-value' fontSize={['INFRASTRUCTURE', 'SER'].includes(key) ? '1rem' : '1.875rem'} color='var(--primary-color)' fontWeight={400}>
                                            {stat.currentYearVal?.toLocaleString() || 0}{properties[key].unit === '%' ? '%' : ``}
                                          </Text>

                                          {
                                            !['INFRASTRUCTURE', 'SER'].includes(key) && <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? 'var(--success-green)' : 'var(--failure-red)'} fontWeight={700}>
                                              {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                              {stat.increaseVal ? +stat.increaseVal >= 0 ? `+${stat.increaseVal.toFixed(1)}` : ` ${stat.increaseVal.toFixed(1)}` : '+0.0'}%
                                            </Text>
                                          }
                                        </div>
                                      </div>
                                      : <div className={`body__item ${stat.type}`} key={properties[key][stat.subType]?.toString() + index}>
                                        <div className="subType">
                                          <Text fontSize='0.75rem' color='var(--text-color)' fontWeight={400}>
                                            {t(`new-fields.${stat.subType}`)}
                                          </Text>
                                        </div>
                                        <div className="increase">
                                          <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? 'var(--success-green)' : 'var(--failure-red)'} fontWeight={700}>
                                            {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                            {stat.increaseVal ? +stat.increaseVal >= 0 ? `+${stat.increaseVal.toFixed(1)}` : ` ${stat.increaseVal.toFixed(1)}` : 0}%
                                          </Text>
                                          <Text className='currentYearVal' style={{ minWidth: '3.75rem' }} fontSize='1rem' color='var(--primary-color)' fontWeight={400}>{stat.currentYearVal?.toLocaleString() || 0}</Text>
                                        </div>
                                      </div>
                                  }
                                  {/* {
                                  stat.subType === 'MOBILE_COMMUNICATION' && <div className="operators-list">
                                    {operatorsList.map((operator, index) =>
                                      <Fragment key={operator.name}>
                                        {stat.additionalInfo?.providers?.includes(operator.name) && <img src={operator.img} alt={operator.name} />}
                                      </Fragment>
                                    )}
                                  </div>
                                } */}
                                </React.Fragment>
                              ))
                            }
                          </div>
                        </BasicStatsItem>}
                </>

              )
            }

            {
              filtered.includes('ADDITIONAL_TYPE') && Object.keys(additionalStats).length > 0
                ? <>
                  {
                    Object.keys(additionalStats).map((key) =>
                      <BasicStatsItem key={key}>
                        <div className="title">
                          <Text color='var(--text-color)' fontWeight={700} fontSize='0.75rem'>{key}</Text>
                          <span>%</span>
                        </div>
                        <div className="body padded">
                          {
                            additionalStats[key].map((stat: any, index: number) => (
                              <div className='body__item' key={index}>
                                <div className="subType">
                                  <Text fontSize='0.75rem' color='var(--gray)' fontWeight={700}>
                                    {stat.additionalInfo?.[`subtype${getLang()}`]}
                                  </Text>
                                </div>
                                <div className="increase">
                                  <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? 'var(--success-green)' : 'var(--failure-red)'} fontWeight={600}>
                                    {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                    {stat.increaseVal}%
                                  </Text>
                                </div>
                                <Text className='currentYearVal' fontSize='1rem' color='var(--primary-color)' fontWeight={700}>{stat.currentYearVal}</Text>
                              </div>
                            ))
                          }
                        </div>
                      </BasicStatsItem>
                    )
                  }
                </>
                : null
            }
          </>
          : <>
          </>
      }
    </>
  )
}

export default BasicStats;
