import React, { FC, useEffect } from 'react'
import { PopulationInfoContainer } from '../info.component'
import { ControlIcon } from '../../../assets/icons'
import { Text } from '../../text.component'
import { controlOptions } from '../../../constants/navlinks.constant'
import { formatDate, getLang } from '../../../utils/helpers.utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface IProps {
  info: any
  onTabSelect: (tab: string) => void
}

const ControlTab: FC<IProps> = ({ info, onTabSelect }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState<string>('');
  const { link } = useParams();

  const handleTabSelect = (tab: string) => {
    setSelectedTab(tab)
    onTabSelect(tab)
  }

  useEffect(() => {
    if (link && controlOptions.includes(link)) {
      setSelectedTab(link);
    }
  }, [link])

  return (
    <PopulationInfoContainer>
      <div className="population-item total">
        <div className="population-item__icon">
          <ControlIcon />
        </div>
        <div className="flex-1">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{info[`name${getLang()}`] || 'Выбранный район'}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.875rem'>{formatDate(info.date).replaceAll('-', '.') || t('no-data')}</Text>
        </div>
        <div className='ttl'>
          <Text color='var(--primary-color)' fontWeight={700} fontSize='0.875rem'>{controlOptions.length}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{t('info.управлений')}</Text>
        </div>
      </div>

      {
        controlOptions.map((item: any) => (
          <div className="population-item clickable" onClick={() => handleTabSelect(item)}>
            <div className="population-item__icon">
              <ControlIcon />
            </div>
            <div className="flex-1 ">
              <Text color={item === selectedTab ? '#118f84' : 'var(--text-color)'} fontWeight={400} fontSize='0.875rem'>{t('control.' + item)}</Text>
            </div>
          </div>
        ))
      }
    </PopulationInfoContainer>
  )
}

export default ControlTab