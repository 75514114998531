import React, { FC, useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ExpandCard } from '../../assets/icons';
import { IGeneralInfo, IPopulationDynamic } from '../../interfaces/snp.interface';
import { currentYear } from '../../utils/helpers.utils';
import ThemeContext, { Theme } from '../../contexts/theme.context';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

interface IProps {
  handleFullScreen: (obj: any) => void;
  data: IGeneralInfo['populationIncreaseDynamicStats']
}

const PopulationDynamic: FC<IProps> = ({ data, handleFullScreen }) => {
  const { t, i18n: { language } } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const [currYear, setCurrYear] = useState(1000);
  const [chartInfo, setChartInfo] = useState<any>(null);

  const getColor = (year: number, isTicks = false) => {
    if (year === currentYear) {
      return theme === Theme.LIGHT ? '#118F84' : '#ffc803';
    } if (year < currentYear) {
      return theme === Theme.LIGHT ? isTicks ? '#98A3A9' : "#92D050" : '#fff';
    } else {
      return theme === Theme.LIGHT ? isTicks ? '#606367' : "#226AC3" : 'rgba(255, 255, 255, 0.5)';
    }
  };

  useEffect(() => {
    const fontCoef = window.innerWidth < 1600 ? 1 : 1.25;
    if (data) {
      const years = data.map((item) => item.year);
      setCurrYear(data.find((item) => item.year === currentYear)?.value || 1000);

      setChartInfo({
        data: {
          labels: years,
          datasets: [
            {
              label: t('populationDetailsStats.population'),
              data: data.map((item: IPopulationDynamic) => (item.value)),
              borderColor: years.map(year => getColor(year)),
              backgroundColor: years.map(year => getColor(year)),
              pointRadius: 4 * fontCoef,
              pointBackgroundColor: years.map(year => getColor(year)),
              borderDash: years.map(year => year < currentYear ? [] : [5, 5]),
              segment: {
                borderDash: (ctx: any) => +ctx.p0DataIndex >= 3 ? [3, 3] : [],
                borderColor: (ctx: any) => ctx.p0DataIndex < 3 ? '#92D050' : '#226AC3',
              },
              spanGaps: true
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
                drawTicks: false,
              },
              position: 'top',
              ticks: {
                color: years.map(year => getColor(year, true)),
                align: 'center',
                baseLine: 'middle',
                font: {
                  size: years.map(year => year === currentYear ? 14 * fontCoef : 12 * fontCoef),
                  weight: years.map(year => year === currentYear ? 700 : 400),
                },
              },
              offset: true,
            },
            y: {
              grid: {
                color: '#c6cacc',
                beginAtZero: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
                maxTicksLimit: 4,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
              color: years.map(year => getColor(year)),
              formatter: function (value: any, context: any) {
                const coef = context.chart.data.datasets[0].data[3] >= 1000 ? 1000 : 1
                return coef === 1 ? value : (value / coef).toFixed(1).toLocaleString();
              },
              font: years.map(year => ({
                size: year === currentYear ? 14 * fontCoef : 12 * fontCoef,
                weight: year === currentYear ? 700 : 400,
              })),
              textAlign: 'center',
              clamp: true,
              anchor: 'start',
              align: (context: any) => {
                const index = context.dataIndex;
                const datasets = context.chart.data.datasets[0].data;
                const average = datasets.reduce((acc: number, item: number) => acc + item, 0) / datasets.length;
                return datasets[index] <= average ? 'end' : 'start';
              },
            }
          },
          layout: {
            backgroundColor: '#F8F7EF',
          },
        }
      })
    }

  }, [data, language]);

  return (
    <BasicStatsItem id="dynamic">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`population-dynamic.title`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{currYear >= 1000 && t('form.thousand')} {t('form.people')}</Text>

        </div>
        <div className="right">
          <span className="unit-container">
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'population-dynamic' })} />
          </span>
        </div>
      </div>
      <div className="body">
        <div className="line-chart-container">
          {chartInfo && <Line data={chartInfo.data} options={chartInfo.options} plugins={[ChartDataLabels]} />}
        </div>
      </div>
    </BasicStatsItem >
  )
};

export default PopulationDynamic;
